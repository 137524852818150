import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { isBrowser } from "../helpers/general-helper"
import "../styles/news.scss"
import {mergePrismicPreviewData} from "gatsby-source-prismic";


const News = ({ pageContext: { newsPage, newsPostsPages } }) => {
  // Merge Prismic Preview data if any.
  const previewData = isBrowser() && window.__PRISMIC_PREVIEW_DATA
  const { prismicBlog } = mergePrismicPreviewData({
    staticData: newsPage.data,
    previewData
  })

  const news = prismicBlog.data
  const posts = newsPostsPages.data.allPrismicBlogPost.edges

	const SEOData = {
		"seo_title": news.seo_title,
		"seo_description": news.seo_description,
		"seo_page_thumbnail": {...news.seo_page_thumbnail}
  }

  var tags = {}, tag_name;
  for (var i in posts) {
    if (typeof posts[i].node.data.content_date !== "number") {
      // parse post dates into Date() in case they're strings
      var post_date = Date.parse(posts[i].node.data.content_date);
      if (isNaN(post_date)) {
        post_date = new Date();
      }
      posts[i].node.data.content_date = post_date;
    }

    if(posts[i].node.tags.length <= 0) {
      continue;
    }

    for( var t in posts[i].node.tags ){
      tag_name = posts[i].node.tags[t];
      tags[tag_name] = tag_name;
    }

    let catsClass = '';
    for( var a in posts[i].node.tags ){
      catsClass += ' cat-' + posts[i].node.tags[a].replace(' ', '-').replace('%20', '-');
    }
    posts[i].node.data['cats'] = catsClass;
  }

  // console.log( tags )

  // Sort tags (categories)
  let catsNav = [];
  for( let t in tags ){
    catsNav.push({ tag: tags[t] });
  }
  catsNav.sort(function(a, b){
    if(a.tag < b.tag) { return -1; }
    if(a.tag > b.tag) { return 1; }
    return 0;
  });
  tags = {};
  for( let t in catsNav ){
    tags[catsNav[t].tag] = catsNav[t].tag;
  }

  // order posts by date
  posts.sort((a, b) => (a.node.data.content_date > b.node.data.content_date) ? -1 : 1)

  return (
    <Layout SEOData={SEOData}>

      <Link to="/" className="logo">Super!com</Link>
      <h2 className="title">News</h2>

      <div className="news">
        <div className="center">

          <h4 className="game-section__title">
            <i className="-handly-centered"></i>
            <span className="game-section__title__text">News <span className="game-section__title__text__accent">&amp; Articles</span></span>
          </h4>

          <div className="news__cats">
            
            <nav className="news__cats__list">
              <a href="#all" className="news__cats__list__item -active" data-cat="all"><span>All</span></a>
                {Object.keys(tags).map((tag, index) => (
                  <a href={'#' + tag.replace(' ', '-').replace('%20', '-').toLowerCase()} key={`news_category_${index}`} className="news__cats__list__item" data-cat={tag.toLowerCase()}><span>{tag}</span></a>
                ))}
            </nav>

          </div>

          <div className="news__roll">

            {posts.map((post, index) => (
              <Link key={`news_post_${index}`} className={`news__roll__item -hidden cat-${post.node.data.cats.replace(' ', '-').replace('%20', '-').toLowerCase()}`} to={`/news/${post.node.uid}`}>
                <span className="news__roll__item__image">
                  <span className="news__roll__item__image__image" style={{ backgroundImage: 'url(' + post.node.data.seo_page_thumbnail.url + ')' }}></span>
                </span>
                <span className="news__roll__item__title">{post.node.data.content_title.text}</span>
                <span className="news__roll__item__date">{ new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                }).format(post.node.data.content_date) }</span>
              </Link>
            ))}

          </div>

        </div>
      </div>

    </Layout>
  )


}

export default News